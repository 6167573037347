import { css } from "@emotion/core"
import "bootstrap/dist/css/bootstrap.min.css"
import classnames from "classnames"
import { h2 } from "css/primitives"
import { colors } from "css/theme"
import { graphql } from "gatsby"
import React, { useState } from "react"
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap"
import "src/queries/featured_media"
import { Banner } from "src/views/resource/components"
import PageLayout from "../components/PageLayout"
import SEO from "../components/seo"
import {
  resourceTypeProperNamesPlural,
  resourceTypes,
} from "../constants/resourceTypes"
import { ResourceCard } from "src/components"

const wrapper = css`
  padding-bottom: 100px;
  overflow: hidden;
  margin-top: -90px;
  background-color: ${colors.darkBackground};
  @media (max-width: 479px) {
    padding-bottom: 75px;
  }
`

const wrapperTop = css`
  background-image: radial-gradient(
    circle at top,
    ${colors.background} 0%,
    black 100%
  );
  background-repeat: no-repeat;
  padding-top: 182px;
  padding-bottom: 175px;
  padding-left: 1rem;
  padding-right: 1rem;
  @media (max-width: 479px) {
    padding-top: 130px;
    padding-bottom: 270px;
  }
`

const heading = [
  h2,
  css`
    line-height: 1.14;
    font-size: 56px;
    color: white;
    text-align: center;
    margin-bottom: 1.5rem;
  `,
]

const headingText = css`
  color: white;
  opacity: 0.7;
  font-size: 20px;
  line-height: 1.6;
  text-align: center;
  max-width: 670px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 0;
`

const blogListWrapper = css`
  padding-top: 3rem;
  margin-top: -136px;
  .nav-link {
    font-size: 16px;
    font-family: var(--heading);
    text-transform: uppercase;
    letter-spacing: 0.9px;
    color: ${colors.gray};
    font-weight: 600;
    border: 0;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .nav-tabs {
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
    border-bottom: 3px solid ${colors.grayBorder};
    margin-bottom: 80px;
  }
  .nav-item {
    margin-bottom: -3px;
    flex-grow: 1;
    text-align: center;
  }
  .nav-link:hover {
    border: 0;
    color: white;
    cursor: pointer;
  }
  .nav-item .active.nav-link {
    color: ${colors.orange};
    background: none;
    border: 0;
    border-bottom: 3px solid ${colors.orange};
  }

  @media (max-width: 1023px) {
    .nav.nav-tabs {
      display: none !important;
    }
  }
  @media (max-width: 767px) {
    margin-top: -200px;
  }
  @media (max-width: 479px) {
    margin-top: -280px;
  }
`

const navTabsWrapper = css``

const postWidth = 336
const postGutterRight = 45

const blogList = css`
  list-style-type: none;
  margin-left: 0;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
  @media (min-width: 768px) and (max-width: 1150px) {
    max-width: ${postWidth * 2 + postGutterRight}px;
  }
  @media (max-width: 767px) {
    max-width: none;
    padding-left: 1rem;
    padding-right: 1rem;
    flex-direction: column;
    padding-bottom: 3rem;
  }
`

const conditions = [
  () => {
    return true
  },
  (doc) => {
    return doc.node.acf.type === resourceTypes.WHITEPAPER
  },
  (doc) => {
    return doc.node.acf.type === resourceTypes.COMPARISON
  },
  (doc) => {
    return doc.node.acf.type === resourceTypes.SOLUTION
  },
  (doc) => {
    return doc.node.acf.type === resourceTypes.CASE_STUDY
  },
]

const IndexPage = ({ data }) => {
  const [activeTab, setActiveTab] = useState("all")
  return (
    <PageLayout options={{ headingBackground: false }}>
      <SEO
        title="Resources"
        description="Comprehensive insights on deception technology, active defence, and how to beat adversaries at their own game."
      />
      <div css={wrapper}>
        <div css={wrapperTop}>
          <h1 css={heading}>Resources</h1>
          <p css={headingText}>
            Comprehensive insights on deception technology, active defence, and
            how to beat adversaries at their own game.
          </p>
        </div>
        <div css={blogListWrapper}>
          <div css={navTabsWrapper}>
            <Nav tabs>
              <NavItem>
                <NavLink
                  onClick={() => {
                    setActiveTab("all")
                  }}
                  className={classnames({ active: activeTab === "all" })}
                >
                  All
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  onClick={() => {
                    setActiveTab(resourceTypes.WHITEPAPER)
                  }}
                  className={classnames({
                    active: activeTab === resourceTypes.WHITEPAPER,
                  })}
                >
                  {resourceTypeProperNamesPlural.WHITEPAPER}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  onClick={() => {
                    setActiveTab(resourceTypes.COMPARISON)
                  }}
                  className={classnames({
                    active: activeTab === resourceTypes.COMPARISON,
                  })}
                >
                  {resourceTypeProperNamesPlural.COMPARISON}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  onClick={() => {
                    setActiveTab(resourceTypes.SOLUTION)
                  }}
                  className={classnames({
                    active: activeTab === resourceTypes.SOLUTION,
                  })}
                >
                  {resourceTypeProperNamesPlural.SOLUTION}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  onClick={() => {
                    setActiveTab(resourceTypes.CASE_STUDY)
                  }}
                  className={classnames({
                    active: activeTab === resourceTypes.CASE_STUDY,
                  })}
                >
                  {resourceTypeProperNamesPlural.CASE_STUDY}
                </NavLink>
              </NavItem>
            </Nav>
          </div>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="all">
              {" "}
              <ul css={blogList}>
                {data.allWordpressWpResources.edges
                  .filter(conditions[0])
                  .map((doc, index) => (
                    <ResourceCard resource={doc.node} key={index} />
                  ))}
              </ul>
            </TabPane>
            <TabPane tabId={resourceTypes.WHITEPAPER}>
              <ul css={blogList}>
                {data.allWordpressWpResources.edges
                  .filter(conditions[1])
                  .map((doc, index) => (
                    <ResourceCard resource={doc.node} key={index} />
                  ))}
              </ul>
            </TabPane>
            <TabPane tabId={resourceTypes.COMPARISON}>
              {" "}
              <ul css={blogList}>
                {data.allWordpressWpResources.edges
                  .filter(conditions[2])
                  .map((doc, index) => (
                    <ResourceCard resource={doc.node} key={index} />
                  ))}
              </ul>
            </TabPane>
            <TabPane tabId={resourceTypes.SOLUTION}>
              {" "}
              <ul css={blogList}>
                {data.allWordpressWpResources.edges
                  .filter(conditions[3])
                  .map((doc, index) => (
                    <ResourceCard resource={doc.node} key={index} />
                  ))}
              </ul>
            </TabPane>
            <TabPane tabId={resourceTypes.CASE_STUDY}>
              {" "}
              <ul css={blogList}>
                {data.allWordpressWpResources.edges
                  .filter(conditions[4])
                  .map((doc, index) => (
                    <ResourceCard resource={doc.node} key={index} />
                  ))}
              </ul>
            </TabPane>
          </TabContent>
        </div>
        <div>
          <Banner data={data} />
        </div>
      </div>
    </PageLayout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query AllResourcesQuery {
    allWordpressWpResources {
      edges {
        node {
          id
          slug
          title
          content
          excerpt
          status
          wordpress_id
          acf {
            type
          }
          ...FeaturedMediaResources
        }
      }
    }
    costSectionImage: file(relativePath: { eq: "homepage/cost-image-2x.png" }) {
      childImageSharp {
        fluid(maxWidth: 530) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
